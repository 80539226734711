import React, { useEffect } from "react";
import { graphql } from "gatsby";

// components
import { SortedCards, CollectionsLinks, Seo } from "../../components";
import Layout from "../../components/Layout";

const Under30 = ({ data }) => {
  const books = data.under30.nodes.filter(book => book.data.RRP <= 30);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [data]);

  return (
    <Layout>
      <Seo title="Under 30" />
      <CollectionsLinks />
      <SortedCards books={books} title="Under €30" />
    </Layout>
  );
};

export const query = graphql`
  query GetUnder30 {
    under30: allAirtable(
      filter: {
        data: { status: { eq: "Available" }, categories: { ne: "Sold Out" } }
      }
      sort: { fields: data___Release_Date, order: DESC }
    ) {
      nodes {
        data {
          RRP
          ...CardParts
        }
      }
    }
  }
`;

export default Under30;
